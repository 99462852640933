import "./contact.scss";

function Contact() {
  return (
    <>
      <div id="contact" className="container p-t-50">
        <div className="row">
          <div className="col-lg-12 justify-center">
            <div className="title-container">
              <span className="title">
                <strong>Contact</strong>
              </span>
            </div>
          </div>
        </div>
        <div className="row p-t-40">
          <div className="col-lg-12 justify-center">
            <img
              className="wojak-contact-img"
              src="./assets/img/contact.png"
              alt="Wojak Contact"
            />
          </div>
        </div>

        <div className="row justify-center">
          <div className="col-lg-3 p-t-50 justify-center">
            <a className="btn-wojak" target="_blank" href="https://t.me/wojakcashofficial" rel="noreferrer">Telegram</a>
          </div>
          <div className="col-lg-3 p-t-50 justify-center">
            <a className="btn-wojak" target="_blank" href="https://twitter.com/wojak_cash" rel="noreferrer">Twitter</a>
          </div>
        </div>

        <div className="row p-t-50 justify-center">
          <div className="col-lg-12 justify-center">
            <div className="contact-container text-center">
              <span className="description">
                $WCH is a meme coin with no intrinsic value or expectation of
                financial return. <br /> There is no formal team or roadmap.
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;
