import "./about.scss";

function About() {
  return (
    <>
      <div className="container p-t-50" id="about">
        <div className="row">
          <div className="col-lg-5 p-t-50 text-center">
            <img
              className="wojak-phone-img"
              src="./assets/img/phone.jpg"
              alt="Wojak Phone"
            />
          </div>

          <div className="col-lg-7 p-t-50">
            <div className="row">
              <div className="col-lg-12">
                <div className="title-container">
                  <span className="title">
                    <strong>About</strong>
                  </span>
                </div>
              </div>
              <div className="row p-t-20">
                <div className="col-lg-12">
                  <div className="about-container">
                    <span className="description">
                      <p>
                        Feels good, looks like Wojak achieved his financial
                        freedom. Or not?
                      </p>
                      <p>
                        Bogdanoff is ready to destroy Wojak’s plans by
                        manipulating the market, with just a simple phone call
                        he is able to load the china fud and dump all the
                        market.
                      </p>
                      <p>
                        <strong>At a random times the smart contract will automatically call
                        Bogdanoff, if he answers he will use the treasury to
                        pump or dump the token.</strong>
                      </p>
                      <p>
                        The telegram bot will automatically alert all Wojaks
                        after a Bogdanoff's call in the groupchat.
                      </p>

                      <p>No one is safe.</p>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default About;
