import "./roadmap.scss";

function Roadmap() {
  return (
    <>
      <div className="container p-t-50" id="roadmap">
        <div className="row">
          <div className="col-lg-12 justify-center">
            <div className="title-container">
              <span className="title"><strong>Roadmap</strong></span>
            </div>
          </div>
        </div>
        
        <div className="row p-t-30">
          <div className="col-lg-12 justify-center">
            <div className="title-container">
              <img src={'/assets/img/roadmap.jpeg'} alt="Sleepy king"/>
            </div>
          </div>
        </div>

        <div className="row tuck">
          <div className="col-lg-4 p-t-50">
            <div className="row phase-container">
              <div className="col-lg-12 justify-center">
                <div className="title-container">
                  <span className="title2"><strong>Phase 1</strong></span>
                </div>
              </div>
              <div className="row p-t-20">
                <div className="col-lg-12">
                  <div className="about-container">
                    <span className="description">
                      <ul>
                        <li>Launch $WCH</li>
                        <li>Bogdanoff telegram bot</li>
                        <li>1k holders</li>
                      </ul>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 p-t-50">
            <div className="row phase-container">
              <div className="col-lg-12 justify-center">
                <div className="title-container">
                  <span className="title2"><strong>Phase 2</strong></span>
                </div>
              </div>
              <div className="row p-t-20">
                <div className="col-lg-12">
                  <div className="about-container">
                    <span className="description">
                      <ul>
                        <li>Coingecko listing</li>
                        <li>Community partnerships</li>
                        <li>More Wojak</li>
                      </ul>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 p-t-50">
            <div className="row phase-container">
              <div className="col-lg-12 justify-center">
                <div className="title-container">
                  <span className="title2"><strong>Phase 3</strong></span>
                </div>
              </div>
              <div className="row p-t-20">
                <div className="col-lg-12">
                  <div className="about-container">
                    <span className="description">
                      <ul>
                        <li>Wojak takeover</li>
                        <li>??</li>
                      </ul>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Roadmap;
