import "./home.scss";
import { Tooltip } from 'react-tooltip'

function Home() {
  return (
    <>
      <Tooltip id="tooltip-tba" content="Coming soon" />
      <div className="container p-t-150" id="home">
        <div className="row">
          <div className="col-lg-6 p-t-50">
            <div className="background-container">
              <div className="background-wojak"></div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="title-container">
                    <span className="title"><strong>$WCH</strong></span>
                  </div>
                </div>
              </div>
              <div className="row p-t-20">
                <div className="col-lg-12">
                  <div className="description-container">
                    <span className="description">
                      <p>
                        Wojak Cash is the money for the people and the final
                        meme token for all of us.
                      </p>
                      <p>
                        Wojak finally left his job at McBurger, he bought some
                        $WCH and he is ready to live his best life.
                      </p>
                      <p>We are all Wojak.</p>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 p-t-50 justify-center">
            <img
              className="wojak-cash-img"
              src="./assets/img/wojak_banner.png"
              alt="Wojak Banner"
            />
          </div>
        </div>

        <div className="row p-t-30">
          <div className="col-lg-3 p-t-30 justify-center">
            <a className="btn-wojak" target="_blank" href="/assets/Cybersecurity_Audit_CTDSEC_WojakCash.pdf">AUDIT</a>
          </div>
          <div className="col-lg-3 p-t-30 justify-center">
            <a className="btn-wojak" data-tooltip-id="tooltip-tba">CHART</a>
          </div>
          <div className="col-lg-3 p-t-30 justify-center">
            <a className="btn-wojak" data-tooltip-id="tooltip-tba">BSCSCAN</a>
          </div>
          <div className="col-lg-3 p-t-30 justify-center">
            <a className="btn-wojak" target="_blank" href="https://t.me/wojakcashofficial" rel="noreferrer">TELEGRAM</a>
          </div>
        </div>        
      </div>
    </>
  );
}

export default Home;
