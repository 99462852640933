import About from "../components/about/about";
import Contact from "../components/contact/contact";
import Header from "../components/header/header";
import Home from "../components/home/home";
import Roadmap from "../components/roadmap/roadmap";
import Tokenomics from "../components/tokenomics/tokenomics";
import "./layout.scss";

function Layout() {
  return (
    <>
      <section>
        <Header></Header>
        <Home></Home>
        <div className="p-t-80 p-b-20">
          <div className="money-separator"></div>
        </div>
        <About></About>
        <div className="p-t-80 p-b-20">
          <div className="money-separator"></div>
        </div>
        <Tokenomics></Tokenomics>
        <div className="p-t-80 p-b-20">
          <div className="money-separator"></div>
        </div>
        <Roadmap></Roadmap>
        <div className="p-t-80 p-b-20">
          <div className="money-separator"></div>
        </div>
        <Contact></Contact>
        <div className="p-t-40 p-b-20">
          <div className="money-separator"></div>
        </div>
      </section>
    </>
  );
}

export default Layout;
