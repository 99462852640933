import "./tokenomics.scss";

function Tokenomics() {
  return (
    <>
      <div className="container p-t-50" id="tokenomics">
        <div className="row">
          <div className="col-lg-7 p-t-50">
            <div className="row">
              <div className="col-lg-12">
                <div className="title-container">
                  <span className="title"><strong>Tokenomics</strong></span>
                </div>
              </div>
              <div className="row p-t-20">
                <div className="col-lg-12">
                  <div className="tokenomics-container">
                    <span className="description">
                      <p>Total Supply: 8,002,446,227,000 $WCH</p>
                      <p>
                        * 5% tax on each transaction <br />
                        * 4% goes to Bogdanoff treasury <br />
                        * 1% goes to the marketing wallet <br />
                      </p>
                      <p>100% fair launched, no private sale, no seed sale, no team tokens.</p>
                      <p>No one can dump on Wojak.</p>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-5 p-t-50 text-center">
            <img
              className="wojak-graph-img"
              src="./assets/img/graph.png"
              alt="Wojak Graph"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Tokenomics;
